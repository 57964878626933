import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() =>
    import('/src/components/form-components/FieldSet')
)
const FieldSpacer = loadable(() =>
    import('/src/components/form-components/FieldSpacer')
)
const Radio = loadable(() => import('/src/components/form-components/Radio'))

const StepSex = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet legend={'Are You:'} groupName={'sex'} required>
            <FieldSpacer grow={'third'}>
                <Radio
                    label={'Male'}
                    groupName={'sex'}
                    name={'sexMan'}
                    value={'MALE'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'third'}>
                <Radio
                    label={'Female'}
                    groupName={'sex'}
                    name={'sexWoman'}
                    value={'FEMALE'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepSex
